import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import styles from './next-steps.module.css'

export const IssueBody = () => {
  //<Img fluid={data.zero.childImageSharp.fluid} />
  const data = useStaticQuery(graphql`
    query {
      zero: file(relativePath: {eq: "character-cog.png"}) {
        childImageSharp {
          fluid(sizes: "(max-width: 100px), 200px", quality: 80) {
            ...GatsbyImageSharpFluid_tracedSVG,
          }
        }
      }
    }
  `)
  return (
    <>
      <Img
        fluid={data.zero.childImageSharp.fluid}
        className={styles.cardPicture}
      />
      <section className={styles.section}>
        <div className={styles.wrapper}>
          <p className={styles.heading}>
            Please try checking out again, or if you continue to run into issues
            either check out our <Link to="/faq">FAQ</Link> or reach out over{` `}
            <a href="mailto: support@secretofthesourcecode.com">email</a>
            {` `}so we can help with a solution.
            <br />
          </p>
        </div>
      </section>
    </>
  )
}
